import FileMetaDTO from "@/dto/files/FileMetaDTO";

export default class SavePaystubRequestDTO {

    private personId!: number;

    private file!: FileMetaDTO;

    private category!: string;

    constructor(personId: number, file: FileMetaDTO, category: string) {
        this.personId = personId;
        this.file = file;
        this.category = category;
    }

}